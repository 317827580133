;(function ($) {
    $(window).on('load', function () {
        var wpcf7Elm = document.querySelector( '.wpcf7' );
        if(wpcf7Elm){
            wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
                if(wpcf7Elm.querySelector('[name="redirect"]')){
                    var redirect = wpcf7Elm.querySelector('[name="redirect"]').value;
                    window.location.href = redirect;
                }
            }, false );
        }
    });
})(jQuery)

