(function($) {

    const slideUp = elem => elem.style.height = `0px`;
    const slideDown = elem => elem.style.height = `${elem.scrollHeight}px`;

    function resizedw(){
        $('.tapped .sub-menu').each(function(){
            $(this).addClass('no-transition');
            slideUp(this);
            slideDown(this);
            $(this).removeClass('no-transition');
        });
    }

    var doit;
    window.onresize = function(){
        clearTimeout(doit);
        doit = setTimeout(resizedw, 100);
    };

    $(window).on('load', function () {

        // function getViewportOffset($e) {
        //     var $window = $(window),
        //       scrollLeft = $window.scrollLeft(),
        //       scrollTop = $window.scrollTop(),
        //       offset = $e.offset();
        //     return {
        //       left: offset.left - scrollLeft,
        //       top: offset.top - scrollTop
        //     };
        // }

        // Header
        $(document).on('click', '.menu-item-has-children', function(e) {
            if(!$(e.target).hasClass('.sub-menu') && !$(e.target).closest('.sub-menu').length){
                if( !$(this).hasClass('tapped') ) {
                    $(this).addClass('tapped');
                    slideDown($(this).find('.sub-menu')[0]);
                } else {
                    $(this).removeClass('tapped');
                    slideUp($(this).find('.sub-menu')[0]);
                }
            }
        });

        // move around some patterns
        $('.custom-developers-2 .side-pattern-6').prependTo($('footer'));
        $('.section-twocolcopy.add-pattern .side-pattern-5').prependTo($('.section-twocolcopy.add-pattern + section'));


        // $('.menu-item-has-children .sub-menu').each( function() {
        //     var viewportOffset = getViewportOffset($(this));
        //     var width = $(this).width();
        //     var buffer = getViewportOffset($('header nav')).left;
        //     var offset = ((viewportOffset.left - buffer) + width) / 11;
        //     $(this).css('left', '-' + offset + 'px');
        // } );


        // add class to select element wrapper
        $('.wpcf7-form select').parent().addClass('select-field');
        // add class to textarea element wrapper
        $('.wpcf7-form textarea').parent().addClass('textarea-field');

        // align testimonials with header
        var amountToMove = 0;
        alignTestimonials();
        function alignTestimonials(){
            amountToMove = ($(window).width() - $('.section-testimonial-slider .swiper-nav-col').outerWidth()) / 2 + 15;
            $('.testimonials__outer').css('left', amountToMove+'px');
        }
        $(window).resize(function(){
            alignTestimonials();
        });

        // add class to logo row if testimonials is immediate next element
        $('.section-logorow + .section-testimonial-slider').prev('.section-logorow').addClass('next-testimonials');

        // add flatten helper to patterns that need flat bottoms
        $('section.flatten').each(function(){
            var flattencolor = '';
            flattencolor = $('.bg-extended', this).attr('style');
            $('+ section', this).prepend('<span class="flatten-helper" style="'+flattencolor+'"></span>');
        });

        // reduce size of subnavs that have no descriptions
        $('.sub-menu').each(function(){
            if(!$('li a + p', this).length){
                $(this).addClass('no-descriptions');
            }
        });

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Accordion for FAQs (jQuery)
        $('.accordion dt.active').next().slideDown()

        $('.accordion').on('click', 'dt', function () {
            $('.accordion dd').slideUp();

            if (!$(this).hasClass('active')) {
                // remove active class from any others that might be active
                $('.accordion dt.active').removeClass('active');
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
            }
        });

        $('.site-header__hamburger').on('click', function(){
            $('html,body').toggleClass('menu-open');
        });


        $('.btn-copycode').click(function(e){
            e.preventDefault();

            var closestModule = $(this).closest('.codeblock');
            var codeSections = $('pre', closestModule);
            var clipboardText = '';

            codeSections.each(function(){
                clipboardText += $(this).text()+'\n\n';
            });

            copyToClipboard(clipboardText);

            $(this).text('Copied To Clipboard!');
        });

        // var rmActive = false;
        // $('[data-readmore]').click(function(e){
        //     e.preventDefault();
        //     var thisRM = $(this).data('readmore');
        //     $('#'+thisRM).slideToggle();

        //     if(rmActive){
        //         rmActive = false;
        //         $('.readmore-initial').text('Read More');
        //     }else{
        //         rmActive = true;
        //         $('.readmore-initial').text('Read Less');
        //     }
        // });

        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://player.vimeo.com/video/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 3000);

        //COUNTDOWN
        // if ($('.countdown-timer').length > 0) {
        //     var date = new Date();
        //     var startMinutes = date.getMinutes();
        //     var startSeconds = date.getSeconds();
        //     var remainingSeconds = 59 - startSeconds;
        //     var remainingMinutes = 0;

        //     if (startMinutes < 15) { remainingMinutes = 15 - startMinutes - 1; }
        //     else if (startMinutes < 30) { remainingMinutes = 30 - startMinutes - 1; }
        //     else if (startMinutes < 45) { remainingMinutes = (45 - startMinutes - 1); }
        //     else { remainingMinutes = (60 - startMinutes - 1); }


        //     var count = (remainingMinutes * 60 + remainingSeconds);
        //     var counter = setInterval(timer, 1000); //1000 will run it every 1 second
            
        //     function progress(timeleft, timetotal) {
        //         var progressBarWidth = 100 - ((timeleft / timetotal) * 100);
        //         $('.progressbar .completed').animate({ width: progressBarWidth + '%' }, 500);
        //     }
        //     function timer() {
        //         count = count - 1;
        //         if (count == -1) {
        //             clearInterval(counter);
        //             return;
        //         }

        //         var seconds = count % 60;
        //         var minutes = Math.floor(count / 60);
        //         var hours = Math.floor(minutes / 60);
        //         minutes %= 60;
        //         hours %= 60;

        //         var minuteString = ' minutes and ';
        //         if (minutes == 1) { minuteString = ' minute and ' }
        //         var secondString = ' seconds';
        //         if (seconds == 1) { secondString = ' second' }

        //         progress((minutes * 60 + seconds), 900);
        //         document.querySelectorAll(".training-text .time-left").forEach(function (selected, index) {
        //             if (seconds < 1 && minutes < 1) {
        //                 selected.innerHTML = $(selected).closest('.countdown-timer').data('end-text');
        //             }
        //             if (minutes < 1) {
        //                 selected.innerHTML = seconds + secondString; // watch for spelling
        //             } else {
        //                 selected.innerHTML = minutes + minuteString + seconds + secondString; // watch for spelling
        //             }
        //         });
                
        //     }
        // }

        // get all sliders, we need to loop them due to different settings + nav
        var swipers = document.querySelectorAll('.swiper-container:not(.control):not(.mobile)');
        var nexted = true;

        swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');
            var testimonials = el.classList.contains('testimonials');

            // slider settings
            var options = {
                speed: 600,
                loop: true,
                slidesPerView: 1,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true,
                },
                navigation:{},
                thumbs:{},
            };

            if( testimonials ) {
                options.autoplay = false;
                options.slidesPerView =  1;
                options.loop = true;
                options.spaceBetween = 20;
                options.observer = true;  
                options.observeParents = true;
                options.autoHeight = true;
                options.navigation = {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                }
                options.breakpoints = {
                    992 : {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    1700: {
                        slidesPerView: 2
                    }
                }
            }

            if(el.hasAttribute('data-spacing')){
                options.spaceBetween = parseInt(el.getAttribute('data-spacing'));
            }

            // init nav
            options.navigation = {
                nextEl: closestSection.querySelector('.swiper-next'),
                prevEl: closestSection.querySelector('.swiper-prev')
            }

            // maybe add gallery controls to the slider
            if(controls){
                options.thumbs.swiper = new Swiper(controls, {
                    speed: 600,
                    loop: true,
                    slidesPerView: 4,
                });
            }

            // init slider
            new Swiper(el, options);
        });

        // mobile sliders, like logo rows etc
        // need to loop in order to get the slide count
        var mobileSwiperObs = [];
        var mobileSwipers = document.querySelectorAll('.swiper-container.mobile');
        mobileSwipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var slideCount = el.querySelectorAll('.swiper-slide').length;

            var options = {
                speed:600,
                slidesPerView: 1,
                watchOverflow: true,
                loop: true,
                spaceBetween: 20,
                pagination:{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    576 : {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3,
                    }
                }
            };

            if(el.hasAttribute('data-showonmobile')){
                options.slidesPerView = el.getAttribute('data-showonmobile');
            }

            // init slider
            new Swiper(el, options);
        });

    });

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 20) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });

    $(window).on('resize load', function(){
        // check if checklist headings run on two lines and vertically center them
        if(window.matchMedia('(max-width: 767px)').matches){
            $('.section-twocolcopy .table-responsive .h3,.section-twocolcopy .table-responsive h3').each(function(){
                if($(this).height() > 38){
                    $(this).addClass('two-lines');
                }else{
                    $(this).removeClass('two-lines');
                }
            });
        }
        $('.section-twocolcopy__col ul li').each(function(){
            if($(this).height() <= 26){
                $(this).addClass('one-line');
            }else{
                $(this).removeClass('one-line');
            }
        });
    });

    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]:not(.btn-copycode)', function (event) {
        var target = $(this).attr('href');

        if(target.indexOf('http') > -1){
            target = '#'+target.split('#')[1];
        }

        if ($(target).length) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $(target).offset().top - $('.site-header').outerHeight()
            }, 1500)
        }
    });

    function copyToClipboard(text) {
        let temp = document.createElement('textarea');
        temp.value = text;
        document.body.appendChild(temp);
        temp.select();
        document.execCommand('copy');
        document.body.removeChild(temp);
    }


})( jQuery );